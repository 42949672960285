import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

export default function Logo({ siteTitle }) {
  return (
    <div className="logo">
      <Link to="/">
        <svg width="206.906" height="67" viewBox="0 0 206.906 67">
          <path
            fill="black"
            d="M174.5,67A32.5,32.5,0,1,1,207,34.5,32.5,32.5,0,0,1,174.5,67Zm0-57A24.5,24.5,0,1,0,199,34.5,24.5,24.5,0,0,0,174.5,10ZM133.915,35.461l-17.5,31.579-6.33-3.5L125.9,35H118V29h16v6h-0.918Zm-27.83-3.921,17.5-31.579,6.33,3.5-17.5,31.579ZM68.5,67A32.5,32.5,0,1,1,101,34.5,32.5,32.5,0,0,1,68.5,67Zm0-57A24.5,24.5,0,1,0,93,34.5,24.5,24.5,0,0,0,68.5,10ZM27.915,35.461l-17.5,31.579-6.33-3.5L19.9,35H12V29H28v6H27.082ZM0.085,31.539L17.585-.039l6.33,3.5L6.415,35.039Z"
          />
        </svg>
      </Link>
    </div>
  )
}

Logo.propTypes = {
  siteTitle: PropTypes.string,
}

Logo.defaultProps = {
  siteTitle: ``,
}
