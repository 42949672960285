import React from "react"

import Layout from "../components/layout"
import Logo from "../components/logo"
import SEO from "../components/seo"

export default function ContactPage() {
  return (
    <Layout>
      <SEO title="About" keywords={[`soso`, `sosolin`]} />
      <div className="aboutPage">
        <Logo />
        <picture>
          <source
            type="image/webp"
            srcset="https://res.cloudinary.com/sosolin/aboutPhoto.webp"
          />
          <source
            type="image/jpeg"
            srcset="https://res.cloudinary.com/sosolin/aboutPhoto.png"
          />
          <img src="https://res.cloudinary.com/sosolin/aboutPhoto.png" alt="" />
        </picture>
        <div>
          <p>I’m Soso, a brand designer and photographer based in Shenzhen.</p>
          <p>
            I’m Soso, I’m a brand designer at Tencent based in Shenzhen, making
            illustrations, logotypes, and motion design for QQ Browser and
            WeSee. Previously, I’m Soso, a brand designer and photographer based
            in Shenzhen. I worked on UI and web design at Lampard Design.
          </p>
          <p>In my spare time, I make fun artwork and websites.</p>
          <p>
            <br />
          </p>
          <p>我是 soso，一位在深圳生活的品牌设计师和摄影师。</p>
          <p>
            目前在腾讯从事品牌设计，曾参与 QQ
            浏览器的闪屏插图设计和品牌升级，微视的动效设计。更早的时候，在兰帕德设计公司从事
            UI 和网页设计工作。
          </p>
          <p>
            我平时喜欢做些好玩的设计，比如这个网站（感谢{" "}
            <a
              href="https://linci.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              @linci
            </a>{" "}
            帮我写出来）
          </p>
          <p>
            你可以{" "}
            <a
              href="mailto:skillinlin@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              发邮件
            </a>{" "}
            给我， 或者关注我的：
            <a
              href="https://www.behance.net/skillin"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bēhance
            </a>{" "}
            <a
              href="https://dribbble.com/skillin"
              target="_blank"
              rel="noopener noreferrer"
            >
              dribbble
            </a>
          </p>
        </div>
      </div>
    </Layout>
  )
}
